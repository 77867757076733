import moment, { Moment } from 'moment';

import {
  DateTimeInputValue,
  DateTimeParser,
  TimeLocality
} from './date-time-parser';

export class MomentDateTimeParser
  implements DateTimeParser<DateTimeInputValue, Moment>
{
  private _moment = moment;

  parse(value: DateTimeInputValue, parseOptions?) {
    const keepLocalTime = this.isLocalTime(parseOptions?.timeLocality);
    return this._moment(value).utc(keepLocalTime);
  }

  epoch(value: DateTimeInputValue) {
    return this._moment(value).unix();
  }

  timeSinceDate(value: DateTimeInputValue, timeUnit) {
    return this._moment().diff(value, timeUnit);
  }

  format(value: DateTimeInputValue, format, parseOptions?) {
    return this.parse(value, parseOptions).format(format);
  }

  toISOString(value: DateTimeInputValue, parseOptions): string {
    const keepLocalTime = this.isLocalTime(parseOptions?.timeLocality);
    // converting to a date first avoids an ugly warning that moment throws.
    const date = new Date(value);
    const parsed = this._moment(date);
    return parsed.toISOString(keepLocalTime);
  }

  private isLocalTime(timeLocality: TimeLocality = TimeLocality.LOCAL) {
    return timeLocality === TimeLocality.LOCAL;
  }
}
