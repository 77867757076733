export interface HttpClient {
  get(url: string, options?: HttpOptions): Promise<Response>;
  put(url: string, options?: HttpOptions): Promise<Response>;
  patch(url: string, options?: HttpOptions): Promise<Response>;
  post(url: string, options?: HttpOptions): Promise<Response>;
  delete(url: string, options?: HttpOptions): Promise<Response>;
  head(url: string, options?: HttpOptions): Promise<Response>;
}

export type CustomHttpErrorHandler = {
  okIf?: (res: Response) => boolean;
};

export type HttpOptions = Omit<RequestInit, 'method'> & CustomHttpErrorHandler;

export enum HttpErrorType {
  CENTRAL = 'central',
  SPOKE = 'spoke'
}

export interface HttpError {
  status: number;
  message: string;
  trace?: string;
  type?: HttpErrorType;
}

export class FetchHttpError extends Error implements HttpError {
  status: number;
  message: string;
  trace?: string;
  type?: HttpErrorType;

  constructor(response: HttpError) {
    super(response.message);

    this.message = response.message;
    this.status = response.status;

    if (response.trace) {
      this.trace = response.trace;
    }
  }
}

export class CentralApiError extends FetchHttpError implements HttpError {
  type = HttpErrorType.CENTRAL;
}

export class SpokeApiError extends FetchHttpError implements HttpError {
  type = HttpErrorType.SPOKE;
}

export function isHttpError(e: any): e is HttpError {
  return typeof e?.status === 'number' && typeof e?.message === 'string';
}
