import type { StorageClient } from '../storage-client';

export type LocalStorageSetValue = string | string[];
export type LocalStorageGetValue = LocalStorageSetValue | null;
export type LocalStorageStorageClientType = StorageClient<
  LocalStorageGetValue,
  LocalStorageSetValue
>;

export class LocalStorageStorageClient
  implements StorageClient<LocalStorageGetValue, LocalStorageSetValue>
{
  private _localStorage = window.localStorage;

  get(key: string): LocalStorageGetValue | null {
    const item = this._localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  }

  remove(key: string): void {
    this._localStorage.removeItem(key);
  }

  set(key: string, value: LocalStorageSetValue): void {
    this._localStorage.setItem(key, JSON.stringify(value));
  }
}
