import { Poller, PollingResponse } from '../poller/poller';
import {
  CentralApiError,
  HttpOptions,
  isHttpError,
  SpokeApiError
} from '../http-client';
import type {
  JsonHttpClient,
  ReadOnlyJsonHttpClient
} from '../json-http-client';
import { DownloadHttpClient } from '../download-http-client';

/**
 * @name SpokeHttpClient
 * @description standard http client used for accessing PHI from a spoke.
 *  Leverages a polling implementation due to our handling of async data
 *  writes on the backend. See ADR link for reasons for this approach.
 * @see doc/adr/0002-polling-phi-access-pattern.md
 * */
export class SpokeHttpClient implements JsonHttpClient, DownloadHttpClient {
  constructor(
    private hubApiHttpClient: JsonHttpClient,
    private s3HttpClient: ReadOnlyJsonHttpClient,
    private poller: Poller
  ) {}

  handleHubRequest = async <T = unknown>(request: Promise<T>) => {
    try {
      return await request;
    } catch (e) {
      if (isHttpError(e)) {
        throw new CentralApiError(e);
      }

      throw e;
    }
  };

  handleSpokeRequest = async <T = unknown>(request: Promise<T>) => {
    try {
      return await request;
    } catch (e) {
      if (isHttpError(e)) {
        throw new SpokeApiError(e);
      }

      throw e;
    }
  };

  getJson = async <T = unknown>(
    url: string,
    options?: HttpOptions
  ): Promise<T> => {
    const documentUrl: string = await this.handleHubRequest(
      this.hubApiHttpClient.getJson(url, options)
    );

    return this.handleSpokeRequest(
      this.poller.poll<T>(() =>
        this.s3HttpClient.getJson<PollingResponse<T>>(documentUrl)
      )
    );
  };

  postJson = async <T = unknown>(
    url: string,
    options?: HttpOptions
  ): Promise<T> => {
    const documentUrl: string = await this.handleHubRequest(
      this.hubApiHttpClient.postJson(url, options)
    );

    return this.handleSpokeRequest(
      this.poller.poll<T>(() =>
        this.s3HttpClient.getJson<PollingResponse<T>>(documentUrl)
      )
    );
  };

  putJson = async <T = unknown>(
    url: string,
    options?: HttpOptions
  ): Promise<T> => {
    const documentUrl: string = await this.handleHubRequest(
      this.hubApiHttpClient.putJson(url, options)
    );

    return this.handleSpokeRequest(
      this.poller.poll<T>(() =>
        this.s3HttpClient.getJson<PollingResponse<T>>(documentUrl)
      )
    );
  };

  patchJson = async <T = unknown>(
    url: string,
    options?: HttpOptions
  ): Promise<T> => {
    const documentUrl: string = await this.handleHubRequest(
      this.hubApiHttpClient.patchJson(url, options)
    );

    return this.handleSpokeRequest(
      this.poller.poll<T>(() =>
        this.s3HttpClient.getJson<PollingResponse<T>>(documentUrl)
      )
    );
  };

  postDownload = async (url: string, options?: HttpOptions): Promise<void> => {
    const signedUrl = await this.handleHubRequest(
      this.hubApiHttpClient.postJson(url, options)
    );

    const attachmentUrl = await this.handleSpokeRequest(
      this.poller.poll<string>(() =>
        this.s3HttpClient.getJson<PollingResponse<string>>(signedUrl)
      )
    );

    window.location.assign(attachmentUrl);
  };
}
