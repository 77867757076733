import asyncRetry from 'async-retry';

export enum RetryStrategy {
  FIXED = 1,
  EXPONENTIAL = 2
}

export interface AsyncRetryConfig {
  retryIntervalMs: number;
  maxAttempts: number;
  strategy: RetryStrategy;
}

export interface RetryFactory<
  Config = {},
  ReturnType extends Function = () => void
> {
  build: (config: Config) => ReturnType;
}

export type AsyncRetryFn = <T>(
  fn: (exit: (e: Error) => void) => Promise<T>
) => Promise<T>;

export class AsyncRetryFactory
  implements RetryFactory<AsyncRetryConfig, AsyncRetryFn>
{
  build(config: AsyncRetryConfig) {
    return <T>(fn) => {
      return asyncRetry<T>(fn, {
        retries: config.maxAttempts,
        factor: config.strategy,
        maxTimeout: config.retryIntervalMs,
        minTimeout: config.retryIntervalMs
      });
    };
  }
}
