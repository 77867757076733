import type { LDUser } from 'launchdarkly-js-client-sdk';

import { Logger, LoggerFactory } from '../logging';
import { FeatureFlagClient } from './FeatureFlagClient';
import { LDFeatureFlagClient, LaunchDarklySdk } from './LDFeatureFlagClient';

export type { FeatureFlagClient } from './FeatureFlagClient';
export { LDFeatureFlagClient } from './LDFeatureFlagClient';

export interface FeatureFlagClientFactoryProps {
  sdk: LaunchDarklySdk;
  clientId: string;
  user?: LDUser;
  logger?: Logger;
}

export class FeatureFlagClientFactory {
  private static client;

  /**
   * @description Initialize the client if we've not already done so. Gives a
   * warning if we attempt to initialize more than once.
   * @param config {FeatureFlagClientFactoryProps}
   */
  static init = async ({
    sdk,
    clientId,
    user = {},
    logger = LoggerFactory.build()
  }: FeatureFlagClientFactoryProps): Promise<void> => {
    if (!this.client) {
      this.client = new LDFeatureFlagClient(sdk, logger);
      await this.client.init(clientId, user);
    } else {
      logger.warn('FeatureFlagClientFactory: init() called multiple times.');
    }
  };

  /**
   * @description return the initialized {FeatureFlagClient} instance
   * @return {FeatureFlagClient}
   */
  static getClient = <T extends string>(): FeatureFlagClient<T> => {
    if (!this.client) throw new GetClientBeforeInitException();
    return this.client;
  };
}

class GetClientBeforeInitException extends Error {
  message =
    'getClient() could not find an instance. Did you call init() first?';
}
