export enum TimeLocality {
  LOCAL,
  UTC
}

export interface ParseOptions {
  timeLocality: TimeLocality;
}

export type DateTimeInputValue = string | number | Date;

export interface DateTimeParser<
  InputValue = DateTimeInputValue,
  ParsedValue = unknown
> {
  parse: (value: InputValue, parseOptions: ParseOptions) => ParsedValue;
  epoch: (value: InputValue) => number;
  timeSinceDate: (value: InputValue, timeUnit: string) => number;
  format: (
    value: InputValue,
    format: string,
    parseOptions?: ParseOptions
  ) => string;

  toISOString(value: InputValue, parseOptions?: ParseOptions): string;
}
