import { UUIDGeneratorFactory } from '../uuid';
import type { UUIDGenerator } from '../uuid';
import { UrlBuilder } from './url-builder';
import { QsUrlBuilder } from './qs-url-builder';

export type { UrlBuilder, ParsedQuery } from './url-builder';
export { QsUrlBuilder } from './qs-url-builder';

export class UrlBuilderFactory {
  static build(): UrlBuilder {
    const uuidGenerator: UUIDGenerator = UUIDGeneratorFactory.build();
    return new QsUrlBuilder(uuidGenerator);
  }
}
