import { DateTimeParser } from './date-time-parser';
import { MomentDateTimeParser } from './moment-date-time-parser';

export { TimeLocality } from './date-time-parser';
export type { DateTimeParser, ParseOptions } from './date-time-parser';

export class DateTimeParserFactory {
  static build(): DateTimeParser {
    return new MomentDateTimeParser();
  }
}
