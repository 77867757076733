import Cookies, { CookieAttributes } from 'js-cookie';

import type { StorageClient } from '../storage-client';

export type CookieStorageClientType = StorageClient<
  string | undefined,
  string,
  CookieAttributes
>;

export class CookieStorageClient implements CookieStorageClientType {
  private _cookies = Cookies;

  get(key: string): string | undefined {
    return this._cookies.get(key);
  }

  remove(key: string): void {
    // we don't know the domain of the cookie
    // so we carpet bomb all possible domains
    // e.g. 123.abc.site.com, abc.site.com, site.com, com
    // e.g. localhost
    // https://stackoverflow.com/a/2959110/1386245
    const hostnameArray = window.location.hostname.split('.');
    for (let idx = 0; idx < hostnameArray.length; idx++) {
      const domainArray = hostnameArray.slice(idx);
      const domain = domainArray.join('.');
      this._cookies.remove(key, { domain });
    }
  }

  set(
    key: string,
    value: string,
    options: CookieAttributes = {}
  ): string | undefined {
    return this._cookies.set(key, value, { secure: true, ...options });
  }
}
