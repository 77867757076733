export const isUrl = (
  url: string,
  options = { requireProtocol: false }
): boolean => {
  // code modified from https://github.com/sindresorhus/is-url-superb
  const lenient = !options.requireProtocol;
  const sanitizedUrl = url.trim();

  if (sanitizedUrl.includes(' ')) {
    return false;
  }

  try {
    new URL(sanitizedUrl);
    return true;
  } catch (e) {
    if (lenient) {
      return isUrl(`https://${sanitizedUrl}`);
    }

    return false;
  }
};
