import { JsonHttpClient, ReadOnlyJsonHttpClient } from '../json-http-client';
import { DownloadHttpClient } from '../download-http-client';
import { Poller } from '../poller/poller';
import { SpokeHttpClient } from './spoke-http-client';
import { SignedS3UrlHttpClientFactory } from '../signed-s3-url-http-client/signed-s3-url-http-client-factory';

export class SpokeHttpClientFactory {
  static readonly POLLING_INTERVAL_MS = 500;

  static build(
    hubHttpClient: JsonHttpClient
  ): JsonHttpClient & DownloadHttpClient {
    const s3HttpClient: ReadOnlyJsonHttpClient =
      SignedS3UrlHttpClientFactory.build();

    const responsePoller = new Poller(this.POLLING_INTERVAL_MS);

    return new SpokeHttpClient(hubHttpClient, s3HttpClient, responsePoller);
  }
}
