import { UrlBuilderFactory, UrlBuilder } from '../../url';
import { HttpClient } from '../http-client';
import { JsonHttpClient } from '../json-http-client';
import { SignedS3UrlHttpClient } from './signed-s3-url-http-client';
import { SimpleHttpClientFactory } from '../simple-http-client/simple-http-client-factory';
import {
  AsyncRetryConfig,
  AsyncRetryFactory,
  AsyncRetryFn,
  RetryFactory
} from '../retry/async-retry-factory';

export class SignedS3UrlHttpClientFactory {
  static build(): HttpClient & JsonHttpClient {
    const baseHttpClient: HttpClient = SimpleHttpClientFactory.build();

    const asyncRetryFactory: RetryFactory<AsyncRetryConfig, AsyncRetryFn> =
      new AsyncRetryFactory();

    const urlBuilder: UrlBuilder = UrlBuilderFactory.build();

    return new SignedS3UrlHttpClient(
      baseHttpClient,
      asyncRetryFactory,
      urlBuilder
    );
  }
}
