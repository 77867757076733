import { Logger } from '../../logging';
import {
  CustomHttpErrorHandler,
  HttpClient,
  HttpError,
  HttpOptions,
  isHttpError
} from '../http-client';
import { HttpVerb } from '../http-verb';

type ExtendedHttpOptions = RequestInit & CustomHttpErrorHandler;

/**
 * @name SimpleHttpClient
 * @description An un-opinionated basic http client for making REST calls.
 * */
export class SimpleHttpClient implements HttpClient {
  constructor(private logger: Logger) {}

  get(url: string, options?: HttpOptions): Promise<Response> {
    return this.request(url, {
      ...options,
      method: HttpVerb.GET
    });
  }

  post(url: string, options?: HttpOptions): Promise<Response> {
    return this.request(url, {
      ...options,
      method: HttpVerb.POST
    });
  }

  put(url: string, options?: HttpOptions): Promise<Response> {
    return this.request(url, {
      ...options,
      method: HttpVerb.PUT
    });
  }

  patch(url: string, options?: HttpOptions): Promise<Response> {
    return this.request(url, {
      ...options,
      method: HttpVerb.PATCH
    });
  }

  delete(url: string, options?: HttpOptions): Promise<Response> {
    return this.request(url, {
      ...options,
      method: HttpVerb.DELETE
    });
  }

  head(url: string, options?: HttpOptions): Promise<Response> {
    return this.request(url, {
      ...options,
      method: HttpVerb.HEAD
    });
  }

  private async request(
    url: string,
    options?: ExtendedHttpOptions
  ): Promise<Response> {
    try {
      const response = await fetch(url, options);
      return SimpleHttpClient.defaultErrorHandler(response, options);
    } catch (e) {
      this.logger.error(e);
      if (isHttpError(e)) {
        throw new SimpleHttpClientError(e);
      }
      throw e;
    }
  }

  private static defaultErrorHandler(
    response: Response,
    options?: ExtendedHttpOptions
  ) {
    if (!response.ok) {
      if (options?.okIf?.(response)) return response;

      throw new SimpleHttpClientError({
        status: response.status,
        message: response.statusText
      });
    }
    return response;
  }
}

export class SimpleHttpClientError extends Error implements HttpError {
  status: number;
  message: string;
  trace?: string;

  constructor(response: HttpError) {
    super(response.message);
    this.message = response.message;
    this.status = response.status;
    if (response.trace) {
      this.trace = response.trace;
    }
  }
}
