import qs from 'qs';
import path from 'path-browserify';

import type { UUIDGenerator } from '../';
import type { UrlBuilder } from './url-builder';
import { isUrl } from './lib/isUrl';

export class QsUrlBuilder implements UrlBuilder {
  private readonly MAX_URL_PARTS_COUNT = 2;

  constructor(private uuidGenerator: UUIDGenerator) {}

  parse = (url: string): qs.ParsedQs => {
    const urlParts = url.split('?');

    // if we just get a url with no query strings, return an empty object
    if (urlParts.length === 1) return {};

    if (urlParts.length > this.MAX_URL_PARTS_COUNT) {
      throw new UrlParseError(url);
    }

    const paramStr = urlParts.pop();

    if (!paramStr) return {};

    return qs.parse(paramStr);
  };

  validate = (url: string, options?): boolean => isUrl(url, options);

  build = (path: string, params: {} = {}) =>
    `${path}${this.formatQueryString(params)}`;

  joinPath = (...pathFragments: string[]): string =>
    path.join(...pathFragments);

  formatQueryString = (params: {} = {}): string => {
    if (Object.keys(params).length === 0) return '';
    return qs.stringify(params, {
      addQueryPrefix: true,
      indices: false
    });
  };

  appendCacheBuster = (url: string): string => {
    const existingParams = this.parse(url);

    const [baseUrl] = url.split('?');

    return this.build(baseUrl, {
      ...existingParams,
      cacheId: this.uuidGenerator.generate()
    });
  };
}

class UrlParseError extends Error {
  constructor(url: string) {
    super(`${url} is not a valid url and cannot be parsed`);
  }
}
