import type { StorageClient } from '../storage-client';

export type InMemoryStorageClientType = StorageClient<string, {}>;
export class InMemoryStorageClient implements InMemoryStorageClientType {
  private store = {};

  get(key: string): string {
    return this.store[key];
  }

  remove(key: string): void {
    delete this.store[key];
  }

  set(key: string, value: any): void {
    this.store[key] = value;
  }
}
