import { UrlBuilder } from '../../url';
import {
  AsyncRetryConfig,
  AsyncRetryFn,
  RetryStrategy
} from '../retry/async-retry-factory';
import type { RetryFactory } from '../retry/async-retry-factory';
import type { HttpClient, HttpError, HttpOptions } from '../http-client';
import type { JsonHttpClient } from '../json-http-client';

/**
 * @name SignedS3UrlHttpClient
 * @description Makes requests to signed S3 URLs. Retries in case the s3 bucket has not yet been created.
 */
export class SignedS3UrlHttpClient implements HttpClient, JsonHttpClient {
  constructor(
    private httpClient: HttpClient,
    private asyncRetryFactory: RetryFactory<AsyncRetryConfig, AsyncRetryFn>,
    private urlBuilder: UrlBuilder
  ) {}

  get = async <T>(signedUrl: string, options: HttpOptions = {}): Promise<T> => {
    this.validateSignedUrl(signedUrl);

    const req = async (exit) => {
      const res = await this.httpClient.get(
        signedUrl,
        SignedS3UrlHttpClient.formatRequestOptions(options)
      );

      if (!res.ok) {
        return this.handleErrors(res, exit);
      }

      return res;
    };

    return this.retry<T>(req);
  };

  post = async <T>(
    signedUrl: string,
    options: HttpOptions = {}
  ): Promise<T> => {
    this.validateSignedUrl(signedUrl);

    const req = async (exit) => {
      const res = await this.httpClient.post(
        signedUrl,
        SignedS3UrlHttpClient.formatRequestOptions(options)
      );

      if (!res.ok) {
        return this.handleErrors(res, exit);
      }

      return res;
    };

    return this.retry<T>(req);
  };

  patch = <T>(signedUrl: string, options: HttpOptions = {}): Promise<T> => {
    this.validateSignedUrl(signedUrl);

    const req = async (exit) => {
      const res = await this.httpClient.patch(
        signedUrl,
        SignedS3UrlHttpClient.formatRequestOptions(options)
      );

      if (!res.ok) {
        return this.handleErrors(res, exit);
      }

      return res;
    };

    return this.retry<T>(req);
  };

  put = <T>(signedUrl: string, options: HttpOptions = {}): Promise<T> => {
    this.validateSignedUrl(signedUrl);

    const req = async (exit) => {
      const res = await this.httpClient.put(
        signedUrl,
        SignedS3UrlHttpClient.formatRequestOptions(options)
      );

      if (!res.ok) {
        return this.handleErrors(res, exit);
      }

      return res;
    };

    return this.retry<T>(req);
  };

  delete = <T>(signedUrl: string, options: HttpOptions = {}): Promise<T> => {
    this.validateSignedUrl(signedUrl);

    const req = async (exit) => {
      const res = await this.httpClient.delete(
        signedUrl,
        SignedS3UrlHttpClient.formatRequestOptions(options)
      );

      if (!res.ok) {
        return this.handleErrors(res, exit);
      }

      return res;
    };

    return this.retry<T>(req);
  };

  head = <T>(signedUrl: string, options: HttpOptions = {}): Promise<T> => {
    this.validateSignedUrl(signedUrl);

    const req = async (exit) => {
      const res = await this.httpClient.head(
        signedUrl,
        SignedS3UrlHttpClient.formatRequestOptions(options)
      );

      if (!res.ok) {
        return this.handleErrors(res, exit);
      }

      return res;
    };

    return this.retry<T>(req);
  };

  getJson = <T>(signedUrl: string): Promise<T> => {
    this.validateSignedUrl(signedUrl);

    const req = async (exit) => {
      const res = await this.httpClient.get(
        signedUrl,
        SignedS3UrlHttpClient.formatRequestOptions()
      );

      if (!res.ok) {
        return this.handleErrors(res, exit);
      }

      return res.json();
    };

    return this.retry<T>(req);
  };

  postJson<T>(signedUrl: string, options: HttpOptions = {}): Promise<T> {
    this.validateSignedUrl(signedUrl);

    const req = async (exit) => {
      const res = await this.httpClient.post(
        signedUrl,
        SignedS3UrlHttpClient.formatRequestOptions(options)
      );

      if (!res.ok) {
        return this.handleErrors(res, exit);
      }

      return res.json();
    };

    return this.retry<T>(req);
  }

  putJson<T>(signedUrl: string, options: HttpOptions = {}): Promise<T> {
    this.validateSignedUrl(signedUrl);

    const req = async (exit) => {
      const res = await this.httpClient.put(
        signedUrl,
        SignedS3UrlHttpClient.formatRequestOptions(options)
      );

      if (!res.ok) {
        return this.handleErrors(res, exit);
      }

      return res.json();
    };

    return this.retry<T>(req);
  }

  patchJson<T>(signedUrl: string, options: HttpOptions = {}): Promise<T> {
    this.validateSignedUrl(signedUrl);

    const req = async (exit) => {
      const res = await this.httpClient.patch(
        signedUrl,
        SignedS3UrlHttpClient.formatRequestOptions(options)
      );

      if (!res.ok) {
        return this.handleErrors(res, exit);
      }

      return res.json();
    };

    return this.retry<T>(req);
  }

  private retry = this.asyncRetryFactory.build({
    maxAttempts: 5,
    retryIntervalMs: 500,
    strategy: RetryStrategy.FIXED
  });

  private handleErrors = (res, exit) => {
    if (res.status === 404) {
      // throwing triggers the retry logic until we exhaust attempts
      throw new SignedS3UrlHttpClientError(res);
    }

    return exit(new SignedS3UrlHttpClientError(res));
  };

  private static formatRequestOptions(options: HttpOptions = {}): HttpOptions {
    const defaultHeaders: HeadersInit = {
      'Content-Type': 'application/json'
    };

    return {
      headers: defaultHeaders,
      ...options
    };
  }

  private validateSignedUrl(signedUrl: string) {
    if (!signedUrl) throw new MissingDocumentUrlError();

    try {
      this.urlBuilder.parse(signedUrl);
    } catch (ex) {
      throw new MalformedDocumentUrlError(signedUrl);
    }
  }
}

export class SignedS3UrlHttpClientError extends Error implements HttpError {
  status: number;
  message: string;

  constructor(response: Response) {
    super(response.statusText);
    this.message = response.statusText;
    this.status = response.status;
  }
}

export class MalformedDocumentUrlError extends Error implements HttpError {
  status: number;
  message: string;

  constructor(payload: any) {
    const message = `${payload} is not a valid url`;
    super(message);
    this.message = message;
    this.status = 400;
  }
}

export class MissingDocumentUrlError extends Error implements HttpError {
  status: number;
  message: string;

  constructor(message = 'missing document url') {
    super(message);
    this.message = message;
    this.status = 400;
  }
}
