import type { initialize, LDClient, LDUser } from 'launchdarkly-js-client-sdk';

import { Logger } from '../';
import { FeatureFlagClient } from './FeatureFlagClient';

export type LaunchDarklySdk = { initialize: typeof initialize };

export class LDFeatureFlagClient<T extends string>
  implements FeatureFlagClient<T>
{
  private _client?: LDClient;

  constructor(private sdk: LaunchDarklySdk, private logger: Logger) {}

  init = async (clientId: string, user: LDUser = {}) => {
    if (!clientId) {
      this.logger.info('No LD client id returned. Cannot enable');
      return;
    }

    try {
      if (!this._client) {
        this._client = this.sdk.initialize(clientId, user);
        await this._client.waitForInitialization();
      } else {
        this.logger.warn('Duplicate feature flag initialization attempted');
      }
    } catch (e) {
      this.logger.error(e);
    }
  };

  isFlagEnabled = (key: T): boolean => {
    try {
      const client = this.getClient();
      return client?.variation(key);
    } catch (e) {
      this.logger.error(e);
      // if we make it here, something went wrong. Just disable the flag.
      return false;
    }
  };

  private getClient = (): LDClient | undefined => {
    if (!this._client) {
      throw new LDClientNotInitializedError();
    }
    return this._client;
  };
}

export class LDClientNotInitializedError extends Error {
  message = 'You must initialize LD client before using';
}
