import { JsonHttpClient } from '../json-http-client';
import { Poller, PollingResponse } from '../poller/poller';
import { HttpOptions } from '../http-client';

/**
 * @name PollingHubHttpClient
 * @description standard http client used for accessing from the hub that
 *  might take a long time and thus, leverages our async polling pattern.
 *  Unlike the SpokeHttpClient, we don't store the response in S3 first
 *  and so can completely skip that step.
 * */
export class PollingHubHttpClient implements JsonHttpClient {
  constructor(
    private hubApiHttpClient: JsonHttpClient,
    private poller: Poller
  ) {}

  getJson = async <T = unknown>(
    url: string,
    options?: HttpOptions,
    onData?: (resp?: PollingResponse<T>) => void
  ): Promise<T> => {
    return this.poller.poll<T>(
      () => this.hubApiHttpClient.getJson(url, options),
      onData
    );
  };

  postJson = async <T = unknown>(
    url: string,
    options?: HttpOptions,
    onData?: (resp?: PollingResponse<T>) => void
  ): Promise<T> => {
    return this.poller.poll<T>(
      () => this.hubApiHttpClient.postJson(url, options),
      onData
    );
  };

  putJson = async <T = unknown>(
    url: string,
    options?: HttpOptions,
    onData?: (resp?: PollingResponse<T>) => void
  ): Promise<T> => {
    return this.poller.poll<T>(
      () => this.hubApiHttpClient.putJson(url, options),
      onData
    );
  };

  patchJson = async <T = unknown>(
    url: string,
    options?: HttpOptions,
    onData?: (resp?: PollingResponse<T>) => void
  ): Promise<T> => {
    return this.poller.poll<T>(
      () => this.hubApiHttpClient.patchJson(url, options),
      onData
    );
  };
}
