import { LoggerFactory } from '../../logging';
import { HttpClient } from '../http-client';
import { SimpleHttpClient } from './simple-http-client';

export class SimpleHttpClientFactory {
  static build(): HttpClient {
    const logger = LoggerFactory.build();
    return new SimpleHttpClient(logger);
  }
}
