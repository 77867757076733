import { ConsoleLogger } from './console-logger';
import { Logger } from './logger';

export type { Logger } from './logger';

export class LoggerFactory {
  static build(): Logger {
    return new ConsoleLogger();
  }
}
