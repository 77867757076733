import Papa, { ParseConfig, ParseError, ParseResult } from 'papaparse';

import { Logger } from '../';
import type { CsvParser } from './CsvParser';

export type CsvFileParserInput = File;
export type CsvFileParserOutput = ParseResult<string>;
export type CsvFileParserConfig = Omit<ParseConfig, 'complete' | 'error'>;

export class CsvFileParser
  implements
    CsvParser<CsvFileParserInput, CsvFileParserOutput, CsvFileParserConfig>
{
  private _parser = Papa;

  constructor(private logger: Logger) {}

  parse = async (
    input: CsvFileParserInput,
    config: CsvFileParserConfig = {}
  ): Promise<CsvFileParserOutput> =>
    new Promise((resolve) => {
      const handleComplete = (results: ParseResult<string>) => {
        resolve(results);
      };

      const handleError = (error: ParseError) => {
        this.logger.error(error.message);
        throw new CsvFileParserException(error);
      };

      this._parser.parse(input, {
        delimiter: ',',
        skipEmptyLines: true,
        complete: handleComplete,
        error: handleError,
        ...config
      });
    });
}

export class CsvFileParserException extends Error {
  rowNumber: number;

  constructor(error: ParseError) {
    super(error.message);
    this.name = error.type;
    this.rowNumber = error.row;
  }
}
