import type { UUIDGenerator } from './UUIDGenerator';

export class MathRandomUUIDGenerator implements UUIDGenerator {
  generate(): string {
    // taken from: https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
    const replacer = (current) => {
      const rand = (Math.random() * 16) | 0,
        replaceWith = current === 'x' ? rand : (rand & 0x3) | 0x8;
      return replaceWith.toString(16);
    };

    const pattern = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';

    return pattern.replace(/[xy]/g, replacer);
  }
}
