import type { UUIDGenerator } from './UUIDGenerator';
import { MathRandomUUIDGenerator } from './MathRandomUUIDGenerator';

export type { UUIDGenerator } from './UUIDGenerator';

export class UUIDGeneratorFactory {
  static build(): UUIDGenerator {
    return new MathRandomUUIDGenerator();
  }
}
