import { JsonHttpClient } from '../json-http-client';
import { Poller } from '../poller/poller';
import { PollingHubHttpClient } from './polling-hub-http-client';

export class PollingHubHttpClientFactory {
  static readonly POLLING_INTERVAL_MS = 500;

  static build(hubHttpClient: JsonHttpClient): JsonHttpClient {
    const responsePoller = new Poller(this.POLLING_INTERVAL_MS);

    return new PollingHubHttpClient(hubHttpClient, responsePoller);
  }
}
