import { sleep } from '../../sleep';
import { HttpError } from '../http-client';

export enum PollingRequestState {
  CACHING = 'CACHING',
  COMPLETE = 'COMPLETE',
  PENDING = 'PENDING',
  FAILED = 'FAILED'
}

export interface PollingResponseComplete<T = unknown> {
  state: PollingRequestState.COMPLETE;
  payload: T;
}

export interface PollingResponsePending<T = unknown> {
  state: PollingRequestState.PENDING;
  payload?: T;
}

export interface PollingResponseCaching<T = unknown> {
  state: PollingRequestState.CACHING;
  payload?: T;
}

export interface PollingResponseFailed {
  state: PollingRequestState.FAILED;
  errorCode: number;
  message: string;
}

export type PollingResponse<T = unknown> =
  | PollingResponseComplete<T>
  | PollingResponsePending
  | PollingResponseFailed
  | PollingResponseCaching;

export class Poller {
  constructor(private POLLING_INTERVAL_MS: number) {}

  poll = async <T = unknown>(
    fn: () => Promise<PollingResponse<T>>,
    onData?: (resp?: PollingResponse<T>) => void
  ): Promise<T> => {
    const res: PollingResponse<T> = await fn();

    if (this.isComplete<T>(res)) {
      onData?.(res);
      return res.payload;
    }

    if (this.isPending<T>(res)) {
      onData?.(res);
      await sleep(this.POLLING_INTERVAL_MS);
      return this.poll(fn, onData);
    }

    if (this.isCaching<T>(res)) {
      onData?.(res);
      await sleep(this.POLLING_INTERVAL_MS);
      return this.poll(fn, onData);
    }

    if (this.isFailed(res)) {
      throw new PollingResponseError(res);
    }

    throw new UnknownPollingResponseError();
  };

  private isCaching = <T>(
    payload: PollingResponse<T>
  ): payload is PollingResponseCaching<T> => {
    return PollingRequestState[payload.state] === PollingRequestState.CACHING;
  };

  private isComplete = <T>(
    payload: PollingResponse<T>
  ): payload is PollingResponseComplete<T> => {
    return PollingRequestState[payload.state] === PollingRequestState.COMPLETE;
  };

  private isPending = <T>(
    payload: PollingResponse<T>
  ): payload is PollingResponsePending<T> => {
    return PollingRequestState[payload.state] === PollingRequestState.PENDING;
  };

  private isFailed = (
    payload: PollingResponse
  ): payload is PollingResponseFailed => {
    return PollingRequestState[payload.state] === PollingRequestState.FAILED;
  };
}

export class PollingResponseError extends Error implements HttpError {
  status: number;
  message: string;

  constructor(res: PollingResponseFailed) {
    super(res.message);
    this.status = res.errorCode;
    this.message = res.message;
  }
}

export class UnknownPollingResponseError extends Error implements HttpError {
  status = 500;

  constructor() {
    super(
      'Unknown polling response detected. This likely means you have a malformed payload.'
    );
  }
}
