import type { StorageClient } from '../storage-client';

export type SessionStorageSetValue = string | string[];
export type SessionStorageGetValue = SessionStorageSetValue | null;
export type SessionStorageClientType = StorageClient<
  SessionStorageGetValue,
  SessionStorageSetValue
>;

export class SessionStorageClient
  implements StorageClient<SessionStorageGetValue, SessionStorageSetValue>
{
  private sessionStorage = window.sessionStorage;

  get(key: string): SessionStorageGetValue | null {
    const item = this.sessionStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  }

  remove(key: string): void {
    this.sessionStorage.removeItem(key);
  }

  set(key: string, value: SessionStorageSetValue): void {
    this.sessionStorage.setItem(key, JSON.stringify(value));
  }
}
