import {
  CookieStorageClient,
  CookieStorageClientType
} from './cookie/cookie-storage-client';
import {
  LocalStorageStorageClientType,
  LocalStorageStorageClient
} from './local-storage/localstorage-storage-client';
import {
  SessionStorageClientType,
  SessionStorageClient
} from './session-storage/session-storage-client';
import {
  InMemoryStorageClient,
  InMemoryStorageClientType
} from './in-memory/in-memory-storage-client';

export type { StorageClient } from './storage-client';
export { CookieStorageClient } from './cookie/cookie-storage-client';
export { LocalStorageStorageClient } from './local-storage/localstorage-storage-client';
export { InMemoryStorageClient } from './in-memory/in-memory-storage-client';

export class CookieStorageClientFactory {
  static build(): CookieStorageClientType {
    return new CookieStorageClient();
  }
}

export class LocalStorageStorageClientFactory {
  static build(): LocalStorageStorageClientType {
    return new LocalStorageStorageClient();
  }
}

export class SessionStorageClientFactory {
  static build(): SessionStorageClientType {
    return new SessionStorageClient();
  }
}

export class InMemoryStorageClientFactory {
  static build(): InMemoryStorageClientType {
    return new InMemoryStorageClient();
  }
}
