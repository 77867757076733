import { LoggerFactory } from '../logging';
import type { CsvParser } from './CsvParser';
import {
  CsvFileParser,
  CsvFileParserConfig,
  CsvFileParserInput,
  CsvFileParserOutput
} from './CsvFileParser';

export type { CsvParser } from './CsvParser';

export type {
  CsvFileParserConfig,
  CsvFileParserInput,
  CsvFileParserOutput
} from './CsvFileParser';

export { CsvFileParser } from './CsvFileParser';

export class CsvFileParserFactory {
  static build(): CsvParser<
    CsvFileParserInput,
    CsvFileParserOutput,
    CsvFileParserConfig
  > {
    const logger = LoggerFactory.build();
    return new CsvFileParser(logger);
  }
}
